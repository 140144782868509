/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from "./header"
import "./layout.css"
import { Box, CssBaseline } from "@mui/material";
import CustomBreadcrumbs from "./custom-breadcrums";
import pattern from '../images/main-pattern.png'
import footerImg from '../images/footer.png'
import CookieBar from "./cookieBar";





const defaultTheme = createTheme() /**Breakpointları kullanabilmek için */

let theme = createTheme({

  palette: {
    primary: {
      main: '#F1F1F1',
      //light: 'rgb(167, 161, 163)'
    },
    secondary: {
      main: '#F95405',
    },
    text: {
      primary: '#5d5b59',
    },
  },
  typography: {
    fontFamily: 'Titillium Web, Helvetica Neue,Arial, Helvetica, sans-serif',
    h1: {
      fontFamily: 'Titillium Web',
      color: '#666569',
      fontSize: '2rem',
      fontWeight: '700'
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family:'Titillium Web';
        font-style:normal;
        font-display: swap;
        font-weight:normal;
      }
      a {
        text-decoration:none;
        color:inherit
      }
      `

    }
  }
});




const Main = ({ children }) => {

  return <main><Box sx={{
    width: '100%',
    padding: 0,
    [theme.breakpoints.up('lg')]: {
      background: `url(${pattern}) 50% 0 repeat-y;`,
      backgroundSize: '100% auto'
    }
  }}>
    <Box sx={{
      position: 'relative',
      minHeight: '500px',
      paddingBottom: '15%',


      [theme.breakpoints.up('sm')]: {
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '100vW;'
      },
      [theme.breakpoints.up('md')]: {
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: 'auto',
        marginBottom: 'auto',
        maxWidth: '100vW;',
        padding: '24px',
        paddingBottom: '200px'
      },
      [theme.breakpoints.up('lg')]: {
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: 'auto',
        padding: '24px',
        marginBottom: 'auto',
        width: '1170px;',
        paddingBottom: '200px'



      },
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'flex-start',
      padding: '1rem',
    }}>{children}</Box>
  </Box></main>
}

const Footer = ({ children }) => {

  return <footer sx={{
    paddingTop: '15%',
    marginTop: '-15%',
    background: `url(${footerImg}) 50% 0 no-repeat;`,
    backgroundSize: '100% auto',
    backgroundPosition: '50% 40px',
    [theme.breakpoints.up('lg')]: {
      backgroundSize: 'auto'
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '200px',
      marginTop: '-200px'
    }
  }}>{children}</footer>
}

const FooterWrap = ({ children }) => {

  return <Box sx={{
    padding: 0,
    backgroundColor: '#fcfcfc',
    borderTop: '2px solid #e2e2e2;',
  }}><Box sx={{
    maxWidth: '1170px',
    paddingLeft: '13px',
    paddingRight: '13px',
    display: 'block',
    marginRight: 'auto',
    marginLeft: 'auto',

    [theme.breakpoints.up('lg')]: {
      paddingLeft: '19px',
      paddingRight: '19px'
    },
    '& > p': {
      fontWeight: 'bold',
      lineHeight: '24px',
      fontSize: '14px',
      padding: '20px 0',
      float: 'left',

      margin: '1.25rem 0 0'
    }
  }}>{children}</Box></Box>
}


const Layout = ({ pageContext, children, location, pageTitle }) => {
  const crumbs = pageContext['breadcrumb']['crumbs']
  const isNotHomepage = !(location.pathname === '/')
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
console.log(theme)

  return (
    <>
      <div data-usecookiebar={true}>
        <ThemeProvider theme={theme}>
          <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
          <CssBaseline />

          <Main >
            {isNotHomepage &&
              <CustomBreadcrumbs
                crumbLabel={pageTitle}
                location={location}
                crumbs={crumbs}
              />
            }

            {children}</Main>
          <Footer>
            <FooterWrap>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', [theme.breakpoints.down('sm')]: { flexDirection: 'column' } }}>
                <Box>
                  <p>
                    © {new Date().getFullYear()}, VAFO PRAHA sr.o. Tüm hakları saklıdır.
                    {` `}
                    Türkiye Distribütörü <a href='https://atlaspet.com.tr' >Atlaspet A.Ş.</a>
                  </p>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-around', minWidth: '30%', [theme.breakpoints.down('sm')]: { justifyContent: 'space-between' } }}>
                  <p>
                    <Link to='/kvkk'>KVKK Metnimiz</Link></p>
                  <p>  <Link to='/kullanim-kosullari'>Kullanım Koşulları</Link></p>

                </Box>

              </Box>
            </FooterWrap>
          </Footer>
          <CookieBar locale='tr-TR' />

        </ThemeProvider>

      </div>


    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
