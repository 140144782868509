import React, { useState, useEffect } from 'react'
import { Link } from "gatsby"
import { Toolbar, AppBar, Menu, Button, Collapse, Divider, List, ListItemText, ListItem, IconButton } from "@mui/material";
import { makeStyles, withStyles } from '@mui/styles';
import { StaticImage } from "gatsby-plugin-image"
import { menuItems } from '../config/menu';
import MenuIcon from '@mui/icons-material/Menu';
import IconExpandLess from '@mui/icons-material/ExpandLess'
import IconExpandMore from '@mui/icons-material/ExpandMore'
import {Crisp} from "crisp-sdk-web"

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props, ref) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const drawerWidth = 240



const useStyles = makeStyles(theme => ({
  root: {
    height: '233px;',
    borderBottom: '1px solid rgba(0, 0, 0, 0.05);',
    backgroundColor: '#FAFAFA!important;',
    [theme.breakpoints.down('sm')]: {
      height: '60px'
    },

  },
  offset: {
    height: '233px;',
    [theme.breakpoints.down('md')]: {
      height: '60px'
    }

  },
  toolbar: {
    position: 'relative',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      marginRight: 'auto',
      marginLeft: 'auto',
      marginTop: 'auto',
      marginBottom: 'auto',
      width: '100vW;'
    },
    [theme.breakpoints.up('md')]: {
      marginRight: 'auto',
      marginLeft: 'auto',
      marginTop: 'auto',
      marginBottom: 'auto',
      maxWidth: '100vW;'
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: 'auto',
      marginLeft: 'auto',
      marginTop: 'auto',
      marginBottom: 'auto',
      width: '1170px;',

    },
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',


  },
  logoWrapper: {
    alignSelf: 'stretch',
    marginTop: 'auto',
    marginBottom: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: '34px'
    },
    [theme.breakpoints.down('md')]: {
      height: '34px'
    },


  },
  logo: {
    margin: "auto",
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: 'auto!important;',
      height: 'auto'
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      width: 'auto!important;',
      height: 'auto'
    }
  },
  menuBar: {
    backgroundColor: '#EDEDED',
    alignSelf: 'stretch',
    float: 'bottom',
    marginBottom: '0px',
    height: '60px',
    borderLeft: '1px solid rgba(0, 0, 0, 0.2);',
    borderRight: '1px solid rgba(0, 0, 0, 0.2);',
    borderTop: '1px solid rgba(0, 0, 0, 0.2);',
    borderRadius: '5px 5px 0px 0px;',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '20px!important;',
    alignItems: 'center',
    paddingLeft: '1rem',



  },
  dropdownContainer: {
    position: 'relative',
    display: 'flex',
    zIndex: 1000,

  },
  dropdownContent: {
    position: 'absolute;',
    top: '100%',
    left: '0px',
    minWidth: '160px;',
    paddingLeft: '0%',


  },
  button: {
    fontWeight: 'bold!important;',
    fontSize: '16px!important;'

  },
  menuIcon: {
    fontSize: '40px',
    color: '#67656A'
  },
  appMenu: {
    width: '100%'
  },
  menuItem: {
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      padding: 0,

    }
  },
}))

export default function Header(props) {
  const [state, setState] = useState({ mobileView: true })
  const { mobileView } = state
  const classes = useStyles()

  useEffect(() => {
    const setResponsiveness = () => {
      if (typeof window !=='undefined') {
        Crisp.configure("c929a9b8-4a61-4868-94a4-80197bfb1193")
      }
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);
  useEffect(()=>{
    localStorage.setItem("isMobile",state.mobileView)
  },[state.mobileView])

  const DesktopHeader = (props) => {
    return (
      <Toolbar className={classes.toolbar}>
        <Logo />
        <DesktopMenu />
      </Toolbar>
    )
  }

  const MobileMenu = (props) => {
    const [componentState, setComponentState] = useState({ anchorEl: null, drawerOpen: false, openItem: null })
    const { drawerOpen, anchorEl, openItem } = componentState
    const handleClick = (id) => {
      if (componentState.openItem === id) {
        setComponentState((prevState) => ({ ...prevState, openItem: null }))
      } else {
        setComponentState((prevState) => ({ ...prevState, openItem: id }))
      }
    }

    const handleDrawerOpen = (event) => {


      setComponentState((prevState) => ({ ...prevState, drawerOpen: true, anchorEl: event.currentTarget }));

    }

    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            variant: "contained",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon className={classes.menuIcon} fontSize='40px' />
        </IconButton>
        <StyledMenu
          {...{
            id: 'custom-menu',
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
            anchorEl: anchorEl

          }}

        >
          <List component='nav' className={classes.appMenu} disablePadding>
            {menuItems.map((item, index) => {
              const hasSubMenus = item.hasOwnProperty('subMenus')
              const ListItemLink = () => {
                return (
                  <ListItem key={index} button component={Link} to={item.link} className={classes.menuItem} state={item.state}>
                    <ListItemText>{item.label}</ListItemText>
                  </ListItem>
                )
              }
              const ListItemToggle = () => (
                <ListItem key={index} button onClick={() => handleClick(index)} className={classes.menuItem}>
                  <ListItemText>{item.label}</ListItemText>
                  {index === openItem ? <IconExpandLess /> : <IconExpandMore />}
                </ListItem>
              )
              return (
                <React.Fragment key={index}>
                  {!hasSubMenus ? <ListItemLink /> :
                    <>
                      <ListItemToggle />
                      <Collapse in={index === openItem} timeout='auto' className={classes.collapseWrapper}>
                        <div className={classes.collapseDiv}>
                          <Divider />
                          <List component='div' disablePadding>
                            {item.subMenus.map((el, key) => (
                              <ListItem key={key} button component={Link} to={el.link} className={classes.menuItem} state={el.state}>
                                <ListItemText inset>{el.label}</ListItemText>
                              </ListItem>
                            ))}
                          </List>

                        </div>
                      </Collapse>
                    </>
                  }
                </React.Fragment>
              )
            })}
          </List>
        </StyledMenu>

        <Logo />
      </Toolbar>
    )
  }

  const DesktopMenu = (props) => {
    const [open, setOpen] = useState()
    const handleClick = (id) => {
      if (open === id) { setOpen() }
      else {
        setOpen(id)
      }
    }
    return (
      <div className={classes.menuBar}>

        {menuItems.map((item, index) => {
          const hasSubmenus = item.hasOwnProperty('subMenus')
          const ButtonAsLink = () =>
            <Button
              key={index}
              style={{ color: item.color }}
              component={Link}
              to={item.link}
              className={classes.button}
              state={item.state}
            >
              {item.label}
            </Button>
          const ButtonAsDropdown = () =>
            <Button
              value={index}
              key={index}
              style={{ color: item.color }}
              component={Link}
              to={item.link}
              onMouseEnter={() => handleClick(index)}
              className={classes.button}
              state={item.state}
            >
              {item.label}
            </Button>

          return (
            <React.Fragment key={`menu-header-${index}`}>
              {!hasSubmenus ?
                <div key={index}
                  className={classes.dropdownContainer}
                >
                  <ButtonAsLink />
                </div>
                :
                <div tabIndex={0}
                  role="button"
                  key={index}
                  className={classes.dropdownContainer}
                  onMouseLeave={() => handleClick(index)}>
                  <ButtonAsDropdown />
                  <Collapse timeout="auto" in={index === open} className={classes.dropdownContent}>

                    <List component='div' style={{ backgroundColor: item.color }}

                    >
                      {item.subMenus.map((item, index) => {
                        return (
                          <ListItem
                            key={index}
                            button
                            component={Link}
                            to={item.link}
                            style={{ color: 'white' }}
                          >
                            <ListItemText>{item.label}</ListItemText>
                          </ListItem>
                        )
                      })}
                    </List>
                  </Collapse>

                </div>
              }
            </React.Fragment>
          )
        })}

      </div>
    )

  }
  const Logo = () => {
    return (
      <div className={classes.logoWrapper}>
        <Link to='/'>
          <StaticImage
            src="../images/logo-brit.png"
            quality={100}
            formats={["auto", "webp", "avif"]}
            alt="Brit Logosu"
            imgClassName={classes.logo}
            className={classes.logoWrapper}
          />

        </Link>
      </div>
    )
  }

  return (
    <header>
      <AppBar sx={theme => ({
        height: '233px;',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05);',
        backgroundColor: '#FAFAFA!important;',
        [theme.breakpoints.down('md')]: {
          height: '60px'
        }

      })} elevation={0} position='static'>
        {mobileView ? <MobileMenu /> : <DesktopHeader />}

      </AppBar>

    </header>
  )

}



