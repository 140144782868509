import React from 'react'
import { useLocation } from '@reach/router'
import CookieConsent from "react-cookie-consent"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import { Button, Typography } from '@mui/material'
import { makeStyles} from '@mui/styles'


const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed;',
        left: '0;',
        bottom: ' 1rem;',
        width: '100%;',
        margin: '0;',
        zIndex: '999;',

    },
    cookieConsent: {
        width: '90%;',
        maxWidth: '100%',
        margin: ' 0 auto;',
        backgroundColor: theme.palette.primary.main,
        color: 'black',
        display: 'flex;',
        flexDirection: 'column;',
        justifyContent: 'space-between;',
        alignItems: 'center;',
        padding: '1rem 2rem;',
        borderRadius: 1,
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
        }
    },
    button: {
        whiteSpace: 'nowrap',
        borderRadius: 0,
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        gap: '2%',
        justifyContent: 'center'
    },
    textWrapper: {
        width: '90%'
    },
    accept:{
        color:'white',
        backgroundColor:'#5a8d03',
        '&:hover':{
            backgroundColor:'white',
            color:'#5a8d03',
            fontWeight:'700'
        }
    }

}))

const pageText = {
    'tr-TR': {
        decline: 'Reddet',
        accept: 'Kabul et',
        message: 'Sitemiz içerisindeki deneyiminizi iyileştirmek için çerez (ve benzeri teknikleri) kullanıyoruz. Çerezler, belirli özellikleri ve sosyal paylaşım işlevini daha iyi deneyimlemenizi, iletilerin size göre uyarlanmasını sağlarlar. “Kabul et”e tıklayarak, çerez kullanımımıza onay vermiş olursunuz.'

    },
    'en-EU': {
        decline: 'Decline',
        accept: 'Accept',
        message: 'We use cookies (and similar techniques) on our site to improve the experience for you, enabling you to benefit from social sharing functionality and to tailor messages that are relevant to you. They also help us understand how our site is being used. By clicking "Accept" you consent to our use of cookies.'
    }
}

const CookieBar = ({ locale }) => {
    const location = useLocation()
    const classes = useStyles()
    const lang = locale || 'tr-TR'

    return (
        <div className={classes.root}>
            <CookieConsent
                cookieName="gatsby-gdpr-google-tagmanager"
                buttonId="confirm"
                buttonText={<Button variant="contained" className={classes.accept}>{pageText[lang].accept}</Button>}
                declineButtonId="decline"
                declineButtonText={<Button variant='outlined' color='secondary'>{pageText[lang].decline}</Button>}
                enableDeclineButton={true}
                disableStyles={true}
                onAccept={() => initializeAndTrack(location)}
                ButtonComponent={Button}
                buttonClasses={classes.button}
                declineButtonClasses={classes.button}
                buttonWrapperClasses={classes.buttonWrapper}
                containerClasses={classes.cookieConsent}
                flipButtons
                acceptOnScroll={true}
                acceptOnScrollPercentage={25}
            >
                <div className={classes.textWrapper}><Typography className="cookieMessage" align='left'>{pageText[lang].message}</Typography></div>
            </CookieConsent>
        </div>
    )
}
export default CookieBar