export const menuItems = [
    {
        label:"ANASAYFA",
        color:"#666569",
        link:"/",
        icon:"",
        
    },
    {
        label:"ÜRÜNLER",
        color:"#82BF37",
        link:"/urunler",
        icon:"",
        state:{fromMenu:true},
        subMenus:[
            {
                label:'KEDİ',
                link:'/urunler/kedi',
                state:{fromMenu:true}
            },
            {
                label:'KÖPEK',
                link:'/urunler/kopek',
                state:{fromMenu:true}
            }
        ]
        
    },
    {
        label:"HAKKIMIZDA",
        color:"#b67a44",
        link:"/hakkimizda",
        icon:"",
        
    },
    {
        label:"İLETİŞİM",
        color:"#7FCEDA",
        link:"/iletisim",
        icon:"",
        
    },

]