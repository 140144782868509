import * as React from 'react'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import './custom-breadcrumbs.css'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    hiddenOnMobile: {
        [theme.breakpoints.down('md')]: {
            display: 'none!important;'
        }
    }
}))

const CustomBreadcrumbs = (props) => {
    const { crumbs, crumbLabel, } = props
    const classes = useStyles()
    console.log(props)
    return (
        <div className={classes.hiddenOnMobile}>
            <Breadcrumb
                crumbLabel={crumbLabel}
                crumbs={crumbs}

            />
        </div>

    )
}

export default CustomBreadcrumbs